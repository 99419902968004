import React, { useState } from 'react';
import logo from './kitchen.png';
import './App.css';
import { Button, Page, Col, Avatar, Text, Spacer, Row, Image } from '@zeit-ui/react'
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import QRCode from "react-qr-code";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC_qteqlk_acu5pvcPq_GsuS-aXTqQiIKw",
  authDomain: "bondigital-432a5.firebaseapp.com",
  databaseURL: "https://bondigital-432a5.firebaseio.com",
  projectId: "bondigital",
  storageBucket: "bondigital.appspot.com",
  messagingSenderId: "141887562250",
  appId: "1:141887562250:web:744a47b08cc1b1967235f3",
  measurementId: "G-MHEVS41BRW"
};

firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();


function App() {
  const [id, setId] = useState("")
  const [url, setUrl] = useState("https://app.bondigital.io/demo")
  const [claimed, setClaimed] = useState(false)
  db.collection("receipts").where('organizationUuid', "==", 'eb2e56fa-abca-11ea-9ad0-31ff6b3a14b3').orderBy('serverTimestamp', 'desc').onSnapshot(function (querySnapshot) {
    var cities = [];
    querySnapshot.forEach(function (doc) {
      cities.push(doc.data().name);
    });
    if (id !== querySnapshot.docs[0].id) {
      setClaimed(false)
    }
    setId(querySnapshot.docs[0].id)
    setUrl("https://app.bondigital.io/receipts/" + id)

    if (querySnapshot.docs[0].data().access !== undefined) {
      if (querySnapshot.docs[0].data().access.length > 0) {
        setClaimed(true)
      }
    }


    console.log(querySnapshot.docs[0].id)
  });

  if (claimed) {
    return (<Page>
      <Row>
        <Col span={24}>
          <Row justify="center">

              <Avatar size="large" src={logo} isSquare></Avatar>

          </Row>
          <Row justify="center">
            <Text size="5em" h1>We're Paperless Today</Text>
          </Row>
          <Spacer></Spacer>
          <Row justify="center" style={{ position: "relative", top: -20 }}>
            <Text size="3em" align="center" style={{ lineHeight: 1 }}>Get a digital receipt<br /><strong>no account needed</strong></Text>
          </Row>
          <Row>
          </Row>
        </Col>
      </Row>
    </Page>)
  }


  return (<Page>
    <Row>
      <Col span={24}>
      <Row justify="center">

              <Avatar size="large" src={logo} isSquare></Avatar>

          </Row>
          <Row justify="center">
            <Text size="4em" h1>WE'RE PAPERLESS</Text>
          </Row>
          <Spacer></Spacer>
          <Row justify="center" style={{ position: "relative", top: -20 }}>
            <Text size="3em" style={{ lineHeight: 1, textAlign: "center" }}>Get a digital receipt<br /><strong>no account needed</strong></Text>
          </Row>
        <Row justify="center">
          <QRCode value={url} />
        </Row>
      </Col>
    </Row>
  </Page>)

}

export default App;
